import React, { Component } from 'react';

class Collapsible extends Component {
	constructor(props){
		super(props);
		this.state = {
			open: props.open
		}
		this.togglePanel = this.togglePanel.bind(this);
	}

	togglePanel(e){
		this.setState({open: !this.state.open})
	}

	render() {
		return (
			<div className="collapsible">
				<div onClick={(e)=>this.togglePanel(e)} className="header">
					{this.props.title}
				</div>
				{this.state.open ? (<div className="content">{this.props.description}</div>) : null}
			</div>
		);
	}		
}	

export default Collapsible;