import React, { Component } from 'react';
import CategoryItem from './../../components/CategoryItem'

const products = [
  { index: 0, title: "Šalovi", imageUrl: "/images/šal.jpg", url:"salovi", products: 
    { name: "sal1", cijena: 100, makingTime: 7, image:"/images/šal.jpg",
      name: "sal2", cijena: 150, makingTime: 14, image:"/images/šal.jpg"
    }
  },
  { index: 1, title: "Trakice", imageUrl: "/images/trakica.jpg", url:"trakice", products: [ "/images/šal.jpg", "/images/šal.jpg"]},
  { index: 2, title: "Ukrasni jastuci", imageUrl: "/images/jastuci.jpg", url:"jastuci", products: [ "/images/jastuci.jpg", "/images/jastuci.jpg"]}
  ]
//svaka kategorija mora imati listu svojih itema koji imaju naziv, cijenu, vrijeme izrade, materijale


class ProductsContainer extends Component {
  render() {
    return (
    	<div id="products" className="block">
        <div className="container">
          <div className="row">

              {products.map(product => {
                return (
                  <CategoryItem key={product.index}
                    url={product.url}
                    imageUrl={product.imageUrl}
                    title={product.title}
                    products={product.products}
                  />
                )
              })}

          </div>
        </div>
          </div>
    );
  }
}

export default ProductsContainer;
