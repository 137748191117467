import React from 'react';

const Service = ({ iconClass, title, description }) => {
  return (
  	 <div className="col-lg-4">
	    <div className="service">
	      <i className={`${iconClass}`}></i>
	      <div className="service-body">
	        <h2>{title}</h2>
	        <p>{description}</p>
	      </div>
	    </div>
	</div>
  )
}

export default Service;