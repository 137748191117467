import React from 'react';

const SliderControl = ({ type, title, handleClick }) => {
  return (
    <button className={`btn btn-${type} btn-primary`} title={title} onClick={handleClick}>
      <i className="fa fa-angle-right"></i>
    </button>
  )
}

export default SliderControl;