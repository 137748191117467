import React, { Component } from 'react';
import Collapsible from './../../components/Collapsible'

const infos = [
    { index: 0, title: "O Knitellu", description: "Knitello je mladi brand čija se ideja pokrenula u siječnju 2019. u Zagrebu. Član smo udruge Kloto, udruge za promicanje kreativnog sadržaja, te tamo možete doći pogledati kako naše radove, tako i radove ostalih kreativnih kolega. Osnovna ideja Knitella je razbijanje mita da su štrikanje i heklanje zastarjeli zanati, već nam pružaju mogućnost izrade modernih, veselih i posebnih predmeta koje nećete vidjeti na drugima.", open:true},
    { index: 1, title: "O materijalima", description: "Materijal proizvoda možete sami izabrati, a ono što mi najčešće koristimo jest materijal koji sadrži 50% vune i 50% akrila. Vuna pruža toplinu, a akril elastičnost.", open: false},
    { index: 2, title: "O dostavi", description: "Član smo udruge Kloto te svoju narudžbu tamo možete pokupiti osobno ili ćemo Vam je dostaviti u udruzi u roku od 24 sata. Koristimo dostavu Hrvatske Pošte te cijenu dostave možete izračunati i izabrati na njhovim stranicama.", open: false}
]

class InfoContainer extends Component {
  render() {
    return (
        <div id="infos" className="block">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-6 order-md-2">
                        <h2>Zašto Knitello</h2>
                        <ul>
                            {infos.map(info => {
                                return (
                                  <Collapsible key={info.index}
                                    title={info.title}
                                    description={info.description}
                                    open={info.open}
                                  />
                                )
                              })}
                        </ul>
                    </div>
                    <div className="col-lg-7 col-md-6 order-md-1">
                        <div className="image-wrapper"></div>
                    </div>
                </div>
            </div>
		</div>
    );
  }
}

export default InfoContainer;
