import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './../../styles/style.scss';

class HeadingBanner extends Component {
  render() {
    return (
    	<div className="banner-wrapper block">
            <div className="overlay"></div>
		      <div className="frontpage-text">
		          <h1>Knitello</h1>
		          <h6>ručno heklani i štrikani predmeti po vašim željima</h6>
		      </div>
		</div>
    );
  }
}

export default HeadingBanner;
