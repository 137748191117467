import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ContactForm from './../../components/ContactForm'
import { HashLink as HashLink } from 'react-router-hash-link';

class Footer extends Component {
  render() {
    return (
    	<footer className="block">
    		<div className="container">
        		<div className="row">
        			<div className="col-md-6">
        				
        			</div>
        			<div className="col-md-6">
        				<div className="links">
	        				<ul>
	                            <div className="socials">
		                            <li>
			                            <a href="https://www.facebook.com/knitello" target="_blank">
			                            	<i className="fa fa-facebook"></i>
			                        	</a>
				                        <a href="https://www.instagram.com/knitello" target="_blank">
				                            <i className="fa fa-instagram"></i>
				                        </a>
		                            </li>
		                            <li>
		                            	<a href="mailto:knitello@hotmail.com">
				                            <i className="fa fa-envelope"></i>
				                            knitello@hotmail.com
				                        </a> 
		                            </li>
		                        </div>
	                        </ul> 
	                    </div> 
        			</div>
        		</div>
        	</div>
		</footer>
    );
  }
}

export default Footer;


/*
<ContactForm theme="dark"/>
*/

/*
 <li>
    <Link to="/trakice">Trakice</Link>
</li>
 <li>
    <Link to="/salovi">Šalovi</Link>
</li>
 <li>
    <Link to="/jastuci">Ukrasni jastuci</Link>
</li>
<li>
    <Link to="/kontakt">Kontakt</Link>
</li>
*/
