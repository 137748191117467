import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ContactForm from './../components/ContactForm'
import Layout from './../components/Layout/Layout'

class ContactFullView extends Component {
  render() {
    return (
    	<Layout theme="light">
	    	<div className="block">
	    		<div className="container">
	        		<div className="row">
	        			<div className="col-md-6">
	        				<ContactForm theme="light"/>
	        			</div>
	        			<div className="col-md-6">
	        				bla
	        			</div>
	        		</div>
	        	</div>
			</div>
		</Layout>
    );
  }
}

export default ContactFullView;
