import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HashLink as HashLink } from 'react-router-hash-link';
import './../../styles/style.scss';

const Header = ({theme}) => {
    return (
       <header className={theme}>
            <div className="site-header top">
                <div className="header-left">
                    <a href="https://www.facebook.com/knitello" target="_blank">
                            <i className="fa fa-facebook"></i>
                        </a>
                        <a href="https://www.instagram.com/knitello" target="_blank">
                            <i className="fa fa-instagram"></i>
                        </a>
                </div>                       
                <div className="header-right">                    
                    <a href="mailto:knitello@hotmail.com">
                        <i className="fa fa-envelope"></i>
                        knitello@hotmail.com
                    </a>         
                </div>                         
            </div>
        </header>
    );
}

export default Header;

/*  <div className="header-left">
        <Link to="/">
            <img className="site-logo" src="/images/logo.png" alt="logo"/>
        </Link>
    </div> 
*/


/*<div className="site-header bottom">                      
    <nav className="header-right">                    
        <ul>
            <li>
                <Link to="/kontakt">Trakice</Link>
            </li>
            <li>
                <Link to="/kontakt">Šalovi</Link>
            </li>
            <li>
                <Link to="/kontakt">Ukrasni jastuci</Link>
            </li>
            <li>
                <Link to="/kontakt">Kontakt</Link>
            </li>
        </ul>          
    </nav>                         
</div>*/
