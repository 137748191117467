import React, { Component } from 'react';
import Slider from './../../components/Slider/Slider'


const testimonials = [
  { index: 0, customer: "Sonja Veldić", testimonial: "Divne trakice 😻 vraćam se sigurno po još proizvoda 🤩"},
  { index: 1, customer: "Gabrijela Hodak", testimonial: "Trakice stigle brzo nakon narudzbe,preeedivne i kvalitetno nspravljene,teta koja ih izradjuje,jako ljubazna i draga,puuuuno hvala❤"},
  { index: 2, customer: "Ana Gradinjan", testimonial: "trakica brzo dostavljena, odlicna, kvalitetna,preporuka 😊"},
  { index: 3, customer: "Melanie Schmidt", testimonial: "Predivno napravljeno , preslatko stoji i pre toplo je ! Jako zadovoljna ☝🏻👏🏻👑😍💋💋"},
  { index: 4, customer: "Ivana Baranj", testimonial: "Predivna stranica. Preporucujem svima. Brza dostava, predivni proizvodi. 💋💗🔝"},
  { index: 5, customer: "Matea Širanović", testimonial: "trakice stigle za dva dana..super su za bebu I za mamu 😊😍"},
  { index: 6, customer: "Irena Lanović", testimonial: "Knitello je jedna mlada i vrijedna studentica koju sam upoznala prilikom preuzimanja moje osvojene nagrade. Rad joj je besprijekoran, uredan i cjenovno prihvatljiviji od drugih! Dala svoju baby merino vunu od koje će mi isplesti još nekoliko trakica po mojoj želji. Love Knitello"}
]

class TestimonialsContainer extends Component {
  render() {
    return (
    	<div id="testimonials" className="block">
          <h2 className="title">Zadovoljni kupci</h2>
          <div id="slider-container"><Slider slides={testimonials}/></div>
		</div>
    );
  }
}

export default TestimonialsContainer;
