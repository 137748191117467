import React from 'react';
import { Link } from 'react-router-dom';

const CategoryItem = ({url, title, imageUrl, products}) => {

  return (
  	 <div className="col-sm-4">
	    <div className="category">
	    	<div className="image-container">
	        	<div className="image-wrapper" style={{backgroundImage:`url(${imageUrl})`}}></div>
	        </div>
			<h6>{title}</h6>
	    </div>
	</div>
  )
}

export default CategoryItem;

/*

<Link to={`/${url}`} className="category">
	<div className="image-container">
    	<div className="image-wrapper" style={{backgroundImage:`url(${imageUrl})`}}></div>
    </div>
	<h6>{title}</h6>
</Link>
*/