import React from 'react';
import ReactDOM from 'react-dom';
import App from './views/App';
import './styles/style.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import 'jquery';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
