import React, { Component } from 'react';
import './../../styles/style.scss';
import HeadingBanner from './HeadingBanner';
import ServicesContainer from './ServicesContainer';
import ProductsContainer from './ProductsContainer';
import InfoContainer from './InfoContainer';
import TestimonialsContainer from './TestimonialsContainer';
import Layout from './../../components/Layout/Layout'

class Frontpage extends Component {
  render() {
    return (
       <Layout theme="dark">
          <HeadingBanner/> 
          <ServicesContainer/> 
          <ProductsContainer/>
          <InfoContainer/> 
          <TestimonialsContainer/>                 
      </Layout>
    );
  }
}

export default Frontpage;
