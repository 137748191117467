import React, { Component } from 'react';
import Service from './../../components/Service'

const services = [
  { index: 0, title: "Ručna izrada", description: "Svaki je predmet ručno izrađen što znači da mu pridajemo posebnu pažnju, vrijeme i ljubav.", iconClass:"fa fa-heart"},
  { index: 1, title: "Unikatni poklon", description: "Ako želite razveseliti dragu osobu, rado ćemo po dogovoru personalizirati predmet i napraviti ga unikatnim.", iconClass:"fa fa-gift"},
  { index: 2, title: "Po mjeri", description: "Predmete izrađujemo po vašim željama, možete birati dimenzije, boju, materijal i ukrase", iconClass:"fa fa-pencil"}
]

class ServicesContainer extends Component {
  render() {
    return (
    	<div id="services" className="block">
        <div className="container">
          <div className="row">

              {services.map(service => {
                return (
                  <Service key={service.index}
                    iconClass={service.iconClass}
                    title={service.title}
                    description={service.description}
                  />
                )
              })}

          </div>
        </div>
		  </div>
    );
  }
}

export default ServicesContainer;
