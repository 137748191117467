import React from 'react';
import { Link } from 'react-router-dom';

const ContactForm = ({theme}) => {

  return (
	<form action="#" method="post" className={theme}>
    	<label>Unesite ime i prezime</label>
        <input type="text" placeholder="Unesite ime i prezime" aria-label="Enter Name"/>
        <label>Unesite e-mail</label>
     	<input type="text" placeholder="Unesite e-mail" aria-label="Enter Email"/>
        <label>Unesite poruku</label>
     	<textarea type="text" placeholder="Unesite poruku" aria-label="Enter message"/>
     	<div className="button-wrapper">
        	<button className="btn btn-primary" type="button">Pošalji</button>
        </div>
    </form>
  )
}

export default ContactForm;