import React, { Component } from 'react';
import Frontpage from './frontpage/Frontpage';
import CategoryFullView from './category/CategoryFullView';
import ContactFullView from './ContactFullView';
import { BrowserRouter as Router, Route } from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <Router>
	      <div>
	        <Route exact path="/" component={Frontpage} />
	      </div>
	  </Router>
    );
  }
}

export default App;


/*
<Route exact path="/kontakt" component={ContactFullView} />
<Route exact path="/:categoryUrl" component={CategoryFullView} />
*/